<template>
  <div class="parent_row clear">
    <div class="col col_20">
      <div class="align_top">
               <span v-if="brand && brand.bio_links && brand.bio_links.length" data-parent="#accordion"
                     data-toggle="collapse" :data-target="'#'+brand._id" v-tooltip="'View links'"
                     class="collapse_icon">
                 <i class="fal fa-angle-down"></i>

                 </span>

        <div class="align_top profile_box"
             :class="{'no_child' : !brand.bio_links.length}">
          <div class="avatar">
            <div class="brand_img"
                 :style="brandImageLink(brand.avatar)"></div>
          </div>
          <div class="content">
            <div class="name">
              {{ limitTextLength(brand.name, 34) }}
            </div>
            <div class="clearfix"></div>
            <div class="url">
              {{ brand.url }}
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class=" text-center col col_10">
      <p>{{ commaSeparatedNumber(brand.bio_links.length) }}</p>

    </div>

    <div class="text-center col col_10">
      <!--<p>{{getBrandTotalVisits(brand._id)}}</p>-->
      <p>{{ commaSeparatedNumber(calculateSum(brand.bio_links, 'total_visitors')) }}</p>
    </div>
    <div class="text-center col col_10">
      <p>{{ commaSeparatedNumber(calculateSum(brand.bio_links, 'unique_visitors')) }}</p>
    </div>
    <!--in bio links conversions will not be show  {{commaSeparatedNumber(calculateSum(brand.bio_links, 'total_clicks_and_conversions'))}} -->
    <div class="text-center col col_10">
      <p>-</p>
    </div>
    <!--in bio links conversion.rate will not show  {{calculateSum(brand.bio_links, 'conversion_rate')}}% -->
    <div class="text-center col col_10">

      <p>-</p>
    </div>
    <div class=" text-center col col_10">
      <p>{{ brand.created_at | relativeCaptilize }}</p>
    </div>
    <div class="text-right col col_10">
      <div class="btn_action">
        <div class="icon_round"
             v-tooltip.top-center="'View Stats'">
                    <span class="text">
                        <a href="javascript:;" @click.prevent="brandDetailView(brand)">
                            <i class="far fa-line-chart"></i>
                        </a>
                    </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default ({
  name: 'bio-analytics-brand-section',
  props: {
    brand: {
      default: {},
      type: Object
    }
  },
  methods: {
    calculateSum (array, keyName) {
      const analyticsArray = JSON.parse(JSON.stringify(array))
      if (!analyticsArray && !analyticsArray.length) {
        return 0
      }
      return analyticsArray.reduce((initialValue, currentValue) => {
        initialValue += currentValue[keyName] ? currentValue[keyName] : 0
        return initialValue
      }, 0)
    }
  }
})
</script>
