<template>
  <div class="analytic_component">
    <transition name="slide">
      <div class=" container">
        <div class=" analytic_component_inner">

          <div class="component_head d-flex align-items-center">
            <div class="head_left">
              <analytics-toggle-btn></analytics-toggle-btn>
            </div>
            <div class="head_right ml-auto d-flex align-items-center justify-content-end">
              <analytics-type v-model="selected_analytic_type"></analytics-type>
              <button
                v-tooltip="'Refresh'"
                class="mr-2 btn---cta btn-border btn-round light-blue btn-bold"
                style="padding: 9px 10px; min-width: 50px;"
                @click="refresh()"
              >
      <span class="">
        <i class="d-flex align-items-center justify-content-center  fal fa-refresh"></i>
      </span>
              </button>
            </div>
          </div>

          <div class="white_box ">
            <div class="box_inner">
              <div class="box_head d-flex align-items-center">
                <div class="left">
                  <h2>Analytics (List View)</h2>

                </div>
                <div class="right more_width ml-auto d-flex align-items-center justify-content-end">

                  <div class="search_input_block no_animation ">
                    <input
                      style="width: 275px;  max-width: 275px;"
                      placeholder="Search Brand or Campaign"
                      v-model="analytics_search"
                      type="text"
                      class=""
                    >
                    <i class="fal fa-search"></i>
                  </div>
                </div>

              </div>
              <div class="box_content">

                <div class="div_table_nested ">
                  <div class="table_head text-capitalize">
                    <div v-if="selected_analytic_type === analytic_types.LINK" class="col col_20">
                      Brand / Campaign / Links
                    </div>
                    <div v-if="selected_analytic_type === analytic_types.BIO_LINK" class="col col_20">
                      Brand / Links
                    </div>
                    <div class="text-center col col_10">
                      Total Links
                    </div>

                    <div class="text-center col col_10">
                      Clicks
                    </div>

                    <div class="text-center col col_10">
                      Unique Clicks
                    </div>

                    <div class="text-center col col_10">
                      Conversions
                    </div>
                    <div class="text-center col col_10">
                      Conv. rate
                    </div>
                    <div class="text-center col col_10">
                      Created
                    </div>
                    <div class="text-right col col_10">
                      Action
                    </div>
                  </div>

                  <div class="table_body">
                    <!--<div style="padding-top: 15px;" v-if="getBrandsLoader.fetchBrands">
                                            <clip-loader :size="size" :color="color"></clip-loader>
                                        </div>-->

                    <template v-if="brandsLoader">
                      <p class="no-rows text-center">
                        <clip-loader
                          :size="size"
                          :color="color"
                        ></clip-loader>
                      </p>
                    </template>

                    <template v-else-if="filteredBrandsList && filteredBrandsList.length > 0">

                      <div
                        class="panel-group"
                        id="accordion"
                      >
                        <!--loop here...........-->

                        <div
                          class="panel main_item"
                          v-for="(brand,brand_index) in filteredBrandsList"
                        >
                          <analytics-brand-section v-if="selected_analytic_type === analytic_types.LINK"
                                                   :brand="brand" :brand_index="brand_index"
                                                   @update="fetchCampaignAnalytics($event)"></analytics-brand-section>
                          <bio-analytics-brand-section v-if="selected_analytic_type === analytic_types.BIO_LINK"
                                                       :brand="brand"></bio-analytics-brand-section>
                          <div
                            :id="brand._id"
                            class="panel-group panel-collapse collapse child_row"
                          >
                            <!--loop here...........-->
                            <div
                              class="top_child child_item panel"
                              v-for="(cta,cta_index) in brand.campaigns"
                              v-if="selected_analytic_type === analytic_types.LINK && brand.campaigns.length > 0"
                            >
                              <analytics-campaign-section
                                :brand="brand"
                                :campaign="cta"
                                :campaign_index="cta_index"
                                :brand_index="brand_index"
                              ></analytics-campaign-section>
                              <div
                                :id="cta._id"
                                class="collapse child_row last_child"
                              >
                                <!--loop here...........-->
                                <clip-loader
                                  v-if="cta.loader"
                                  :size="size"
                                  :color="color"
                                ></clip-loader>
                                <template v-else>
                                  <template v-if="cta.links && cta.links.data">
                                    <div
                                      class="child_item "
                                      v-for="link in cta.links.data"
                                    >
                                      <analytics-link-section
                                        :link="link"
                                        :campaign="cta"
                                        :brand="brand"
                                      ></analytics-link-section>
                                    </div>

                                    <div
                                      class="child_item inner_pagination"
                                      v-if="cta.total_links > 10"
                                    >
                                      <div class="clear pagination_container">
                                        <button
                                          v-if="cta.links.current_page > 1"
                                          @click.prevent="fetchCampaignByLinks(cta.links.current_page-1, brand._id, cta._id, cta_index, brand_index, cta)"
                                          class="btn_prev btn--cta btn--small"
                                        >
                                          <i
                                            class="icon_prev fa fa-caret-left"
                                            aria-hidden="true"
                                          ></i><span>previous</span>
                                        </button>
                                        <button
                                          v-else
                                          class="btn_prev btn--cta btn--small"
                                          disabled="disabled"
                                        >
                                          <i
                                            class="icon_prev fa fa-caret-left"
                                            aria-hidden="true"
                                          ></i><span>previous</span>
                                        </button>

                                        <span
                                          class="numbering">{{ cta.links.current_page }} of {{
                                            cta.links.last_page
                                          }}</span>

                                        <button
                                          @click.prevent="fetchCampaignByLinks(cta.links.current_page+1, brand._id, cta._id, cta_index, brand_index, cta)"
                                          v-if="cta.links.current_page < cta.links.last_page"
                                          class="btn_next btn--cta btn--small"
                                        >
                                          <span>next</span><i
                                          class="icon_next fa fa-caret-right"
                                          aria-hidden="true"
                                        ></i>

                                        </button>
                                        <button
                                          v-else
                                          disabled="disabled"
                                          class="btn_next btn--cta btn--small"
                                        >
                                          <span>next</span><i
                                          class="icon_next fa fa-caret-right"
                                          aria-hidden="true"
                                        ></i>

                                        </button>

                                      </div>

                                    </div>
                                  </template>

                                </template>

                              </div>
                            </div>

                            <bio-analytics-links-section :brand="brand"
                                                         v-if="selected_analytic_type === analytic_types.BIO_LINK"></bio-analytics-links-section>

                          </div>
                        </div>
                      </div>

                    </template>
                    <template v-else>
                        <p class="no-rows">
                          <i class="fab fa-bandcamp" aria-hidden="true"></i>{{ getFilteredMessage('brand/campaigns', analytics_search) }}
                        </p>
                    </template>
                  </div>
                </div>
              </div>

            </div>

            <template v-if="getAnalytics.totalItems > getAnalytics.limit">
              <pagination
                :clickedMethod="processAnalyticsPagination"
                :resultCount="getAnalytics.totalItems"
                :itemsPerPage="getAnalytics.limit"
                :currentPage="getAnalytics.page"
                :totalPagesNumber="getAnalytics.totalPages"
              ></pagination>
            </template>

          </div>
        </div>

      </div>

    </transition>
  </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.toggle_btn_link {
  /*border: 1px solid #ccc;*/
}

</style>

<script>

import { mapActions, mapGetters } from 'vuex'

import Dropdown from '@/ui/Dropdown.vue'
import AnalyticsToggleBtn from '../AnalyticsToggleBtn.vue'
import AnalyticsBrandSection from './AnalyticsBrandSection'
import BioAnalyticsBrandSection from './BioAnalyticsBrandSection'
import BioAnalyticsLinksSection from './BioAnalyticsLinksSection'
import AnalyticsCampaignSection from './AnalyticsCampaignSection'
import AnalyticsLinkSection from './AnalyticsLinkSection'
import { DARK_PURPLE_LOADER_COLOR, ANALYTIC_TYPE } from '@/common/constants'
import AnalyticsType from '../AnalyticsType'

export default ({
  'name': 'Analytics',
  'components': {
    Dropdown,
    AnalyticsToggleBtn,
    AnalyticsBrandSection,
    AnalyticsCampaignSection,
    AnalyticsLinkSection,
    BioAnalyticsBrandSection,
    BioAnalyticsLinksSection,
    AnalyticsType
  },
  data () {
    return {
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      currentTab: 'tab1',
      analytics_search: '',
      isPermission: false,
      overview: [],
      brandsLoader: true,
      selected_analytic_type: ANALYTIC_TYPE.LINK,
      analytic_types: ANALYTIC_TYPE
    }
  },
  created () {
    this.analytics_search = ''
    this.getListStats()
  },
  mounted () {
    if (window.localStorage.getItem('access_password_protected') === 'true') {
      this.isPermission = true
    }
  },
  computed: {
    ...mapGetters([
      'getComponentBrandList',
      'getBrands',
      'getAnalytics',
      'getAnalyticsListBrands'
    ]),
    filteredBrandsList () {
      if (this.analytics_search && this.analytics_search.trim().length > 0) {
        let filteredArray = this.overview.filter(item => {
          if (item.name.toLowerCase().includes(this.analytics_search.toLowerCase())) {
            return true
          }

          let campaignSearched = (item.campaigns.filter(campaign => campaign.name.toLowerCase().includes(this.analytics_search.toLowerCase())))
          return (campaignSearched.length > 0)
        })
        return filteredArray
      } else {
        return this.overview
      }
    }
  },
  beforeDestroy () {
    // setting the brands all to the null. As we are appending the links on the fly so that we don't have
    // to perform the extra requests etc.
    this.getComponentBrandList.forEach(item => {
      if (item && item.campaign) {
        item.campaign.forEach(campaignItem => {
          this.$set(campaignItem, 'links', [])
        })
      }
    })
  },

  methods: {
    ...mapActions([
      'listViewStatistics',
      'fetchBrandListAnalytics',
      'fetchCampaignListAnalytics',
      'fetchBioLinkListAnalytics'
    ]),
    async getListStats () {
      this.brandsLoader = true
      await this.listViewStatistics().then(val => {
        this.overview = val
        this.brandsLoader = false
        this.fetchBrandAnalytics()
        this.fetchBioLinkAnalytics()
      })
    },
    fetchBrandAnalytics () {
      this.overview.forEach((brand) => {
        this.fetchBrandListAnalytics(brand._id).then(val => {
          brand.fetchStats = true
          brand.total_visitors = val.aggregations.total_visitors.value
          brand.unique_visitors = val.aggregations.unique_clicks.value
          brand.total_clicks_and_conversions = val.aggregations.clicked_conversions.doc_count
          brand.conversion_rate = this.computeConversionRate(val.aggregations)
        })
      })
    },
    fetchCampaignAnalytics (brand_index) {
      this.overview[brand_index].campaigns.forEach((campaign) => {
        this.fetchCampaignListAnalytics(campaign._id).then(val => {
          campaign.fetchStats = true
          campaign.total_visitors = val.aggregations.total_visitors.value
          campaign.unique_visitors = val.aggregations.unique_clicks.value
          campaign.total_clicks_and_conversions = val.aggregations.clicked_conversions.doc_count
          if (campaign.total_visitors <= 0) {
            campaign.conversion_rate = 0
          } else {
            const digits = 2
            let value = (campaign.total_clicks_and_conversions / campaign.total_visitors) * 100
            value = value * Math.pow(10, digits)
            value = Math.round(value)
            campaign.conversion_rate = value / Math.pow(10, digits)
          }
        })
      })
    },
    fetchBioLinkAnalytics () {
      this.overview.forEach((brand) => {
        brand.bio_links.forEach((bio) => {
          this.fetchBioLinkListAnalytics(bio._id).then(res => {
            bio.bioFetchStats = true
            bio.total_hits = res.hits.total
            bio.total_visitors = res.hits.total
            bio.unique_visitors = res.aggregations.unique_clicks.value
          })
        })
      })
    },
    refresh () {
      this.getListStats()
    }
  },
})
</script>
