<template>
  <div class="clear">

    <div class="col col_20">
      <div class="padding_left align_center"
           :class="{'no_child' : !campaign.total_links || campaign.total_links <= 0}">
        <template v-if="campaign.total_links > 0">
                  <span v-if="!campaign.fetch_links"
                        @click.prevent="fetchLinks(campaign, brand,campaign_index, brand_index)"
                        data-toggle="collapse" :data-parent="'#'+brand._id" v-tooltip="'View links'"
                        :data-target="'#'+campaign._id"
                        class="collapse_icon">
                   <i class="fal fa-angle-down"></i>

                    <!--    @click.prevent="getLinksPagination(campaign.page ,campaign._id,campaign_index,brand_index)"-->

                    </span>
          <span v-else data-toggle="collapse"
                :data-parent="'#'+brand._id"
                :data-target="'#'+campaign._id"
                v-tooltip="'View links'"
                class="collapse_icon">
                                                                 <i class="fal fa-angle-down"></i>
                                                                </span>
        </template>

        <p>{{ campaign.name }}</p>
      </div>
    </div>
    <div class=" text-center col col_10">
      <p>{{ commaSeparatedNumber(getCampaignTotalLinks) }}</p>
    </div>

    <div class="text-center col col_10">
      <!--            <p>{{getCtaTotalVisits(campaign._id,brand._id)}}</p>-->
      <p v-if="campaign.fetchStats">{{ commaSeparatedNumber(getCampaignTotalVisitors) }}</p>
      <skeleton v-else></skeleton>
    </div>

    <div class="text-center col col_10">
      <!--<p>
          {{getCtaTotalVisits(campaign._id,brand._id,'unique')}}</p>-->
      <p v-if="campaign.fetchStats">{{ commaSeparatedNumber(getCampaignUniqueVisitors) }}</p>
      <skeleton v-else></skeleton>
    </div>

    <div class="text-center col col_10">
      <!--            <p>{{getCtaTotalEngagement(campaign._id,brand._id)}}</p>-->
      <p v-if="campaign.fetchStats">{{ commaSeparatedNumber(getCampaignTotalClicksAndConversions) }}</p>
      <skeleton v-else></skeleton>
    </div>
    <div class="text-center col col_10">
      <!--<p>
          {{getRate(getCtaTotalEngagement(campaign._id,brand._id),getCtaTotalVisits(campaign._id,brand._id))}}%</p>-->
      <p v-if="campaign.fetchStats">{{ getCampaignConversionRate }}%</p>
      <skeleton v-else></skeleton>
    </div>
    <div class="text-center col col_10">
      <p>{{ campaign.created_at | relativeCaptilize }}</p>
    </div>
    <div class="text-right col col_10">
      <div class="btn_action">
        <div class="icon_round"
             v-tooltip.top-center="'View Stats'">
                        <span class="text">
                            <a href="javascript:;"
                               @click.prevent="campaignDetailView(brand, campaign)">
                                     <i class="far fa-line-chart"></i>
                            </a>

                        </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default ({
  name: 'analytics-campaign-section',
  props: {
    campaign: {
      type: Object
    },
    brand: {},
    campaign_index: {},
    brand_index: {}

  },
  computed: {
    getCampaignTotalLinks () {
      return (this.campaign.total_links) ? this.campaign.total_links : 0
    },
    getCampaignTotalVisitors () {
      return (this.campaign.total_visitors) ? this.campaign.total_visitors : 0
    },
    getCampaignUniqueVisitors () {
      return (this.campaign.unique_visitors) ? this.campaign.unique_visitors : 0
    },
    getCampaignTotalClicksAndConversions () {
      return (this.campaign.total_clicks_and_conversions) ? this.campaign.total_clicks_and_conversions : 0
    },
    getCampaignConversionRate () {
      return (this.campaign.conversion_rate) ? this.campaign.conversion_rate : 0
    }
  },
  methods: {
    async fetchLinks (campaign, brand, campaign_index, brand_index) {
      await this.fetchCampaignByLinks(campaign.page, brand._id, campaign._id, campaign_index, brand_index, campaign)
      // this.fetchLinksAnalyticsMixins(campaign)
    }
  }
})
</script>
