<template>
    <div class="clear">

        <div class="col col_20">

            <div class="padding_left align_top profile_box cta_link_box">
                <div class="avatar">
                    <div v-if="link.image"
                         class="brand_img"
                         :style="brandImageLink(link.image)"></div>

                    <div v-else class="brand_img"
                         style='background: url("/img/globe.svg") center center / cover;'></div>

                </div>
                <div class="content">

                    <div class="name">
                        {{link.page_title}} |{{link.page_sub_title}}
                    </div>
                    <div class="clearfix"></div>

                    <div class="url"
                         v-if="link.state === 'active'">

                        <template v-if="getSiteDetails.isWhiteLabel && getSiteDetails.custom_domain_name">
                            https://{{getSiteDetails.custom_domain_name}}{{link.shorten_url}}
                            <a :href="getSiteDetails.custom_domain_name + link.shorten_url"
                               target="_blank"
                               class="external_link">
                                <i class="far fa-link"></i>
                            </a>
                        </template>

                        <template>
                            https://{{link.shorten_domain}}/{{link.shorten_url}}
                            <a :href="'https://' + link.shorten_domain + '/' + link.shorten_url"
                               target="_blank"
                               class="external_link">
                                <i class="far fa-link"></i>
                            </a>
                        </template>
                    </div>
<!--                    <div class="url" v-else>-->
<!--                        <template-->
<!--                                v-if="campaign.custom_domain && campaign.custom_domain.url">-->
<!--                            {{campaign.custom_domain.url}}{{link.shorten_url}}-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                            {{link.domain_name}}{{link.shorten_url}}-->
<!--                        </template>-->
<!--                        <a href="javascript:;"-->
<!--                           class="external_link">-->
<!--                            <i class="far fa-link"></i>-->
<!--                        </a>-->
<!--                    </div>-->
                </div>
            </div>

        </div>
        <div class="text-center col col_10">
            <p>-</p>
        </div>

        <div class="text-center col col_10">
            {{getLinkTotalVisitors}}
        </div>
        <div class="text-center col col_10">
            {{getLinkUniqueVisitors}}
        </div>
        <div class="text-center col col_10">
            {{getLinkTotalClicksAndConversions}}
        </div>
        <div class="text-center col col_10">
            {{getLinkConversionRate}}
        </div>
        <div class="text-center col col_10">
            <p>{{link.created_at |
                relativeCaptilize}}</p>
        </div>
<!--        <div class="text-right col col_10">-->
<!--            <div class="btn_action">-->
<!--                <div class="icon_round"-->
<!--                     v-tooltip.top-center="'View Stats'">-->
<!--                    <a class="text"-->
<!--                       @click.prevent="linkDetailView(brand, campaign, link)">-->
<!--                        <i class="far fa-line-chart"></i>-->
<!--                    </a>-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->
    </div>
</template>
<script>
import { shortenDomainURL } from '@/config/api'

export default ({
  name: 'bio-analytics-link-details-section',
  props: {
    link: {},
    brand: {}
  },
  data () {
    return {
      shorten_domain_url: shortenDomainURL
    }
  },
  computed: {
    getLinkTotalLinks () {
      return (this.link.total_links) ? this.link.total_links : 0
    },
    getLinkTotalVisitors () {
      return (this.link.total_visitors) ? this.link.total_visitors : 0
    },
    getLinkUniqueVisitors () {
      return (this.link.unique_visitors) ? this.link.unique_visitors : 0
    },
    getLinkTotalClicksAndConversions () {
      return (this.link.total_clicks_and_conversions) ? this.link.total_clicks_and_conversions : 0
    },
    getLinkConversionRate () {
      return (this.link.conversion_rate) ? this.link.conversion_rate : 0
    }
  },
  methods: {

  }
})
</script>
