<template>
  <div class="parent_row clear">
    <div class="col col_20">
      <div class="align_top">
               <span v-if="brand && brand.campaigns && brand.campaigns.length" data-parent="#accordion"
                     data-toggle="collapse" :data-target="'#'+brand._id" v-tooltip="'View campaigns'"
                     class="collapse_icon"
               @click="$emit('update',brand_index);">
                 <i class="fal fa-angle-down"></i>

                 </span>

        <div class="align_top profile_box"
             :class="{'no_child' : !brand.campaigns.length}">
          <div class="avatar">
            <div class="brand_img"
                 :style="brandImageLink(brand.avatar)"></div>
          </div>
          <div class="content">
            <div class="name">
              {{ limitTextLength(brand.name, 34) }}
            </div>
            <div class="clearfix"></div>
            <div class="url">
              {{ brand.url }}
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class=" text-center col col_10">
      <p>{{ commaSeparatedNumber(brand.total_links) }}</p>

    </div>

    <div class="text-center col col_10">
      <!--<p>{{getBrandTotalVisits(brand._id)}}</p>-->
      <p v-if="brand.fetchStats">{{ commaSeparatedNumber(brand.total_visitors) }}</p>
      <skeleton v-else></skeleton>
    </div>
    <div class="text-center col col_10">
      <!--            <p>{{getBrandTotalVisits(brand._id,'unique')}}</p>-->
      <p v-if="brand.fetchStats">{{ commaSeparatedNumber(brand.unique_visitors) }}</p>
      <skeleton v-else></skeleton>
    </div>
    <div class="text-center col col_10">
      <p v-if="brand.fetchStats">{{ commaSeparatedNumber(brand.total_clicks_and_conversions) }}</p>
      <skeleton v-else></skeleton>
      <!--            <p>{{getBrandTotalEngagement(brand._id)}}</p>-->
    </div>
    <div class="text-center col col_10">
      <!--<p>
          {{getRate(getBrandTotalEngagement(brand._id),getBrandTotalVisits(brand._id))}}%</p>-->
      <p v-if="brand.fetchStats">{{ brand.conversion_rate }}%</p>
      <skeleton v-else></skeleton>
    </div>
    <div class=" text-center col col_10">
      <!--          {{brand.created_at.$date.$numberLong}}-->
      <p>{{ brand.created_at | relativeCaptilize }}</p>
    </div>
    <div class="text-right col col_10">
      <div class="btn_action">
        <div class="icon_round"
             v-tooltip.top-center="'View Stats'">
                    <span class="text">
                        <a href="javascript:;" @click.prevent="brandDetailView(brand)">
                            <i class="far fa-line-chart"></i>
                        </a>
                    </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default ({
  name: 'analytics-brand-section',
  props: {
    brand: {
      default: {},
      type: Object
    },
    brand_index: {
      default: {},
      type: Number
    }
  },
  methods: {
    ...mapActions(['fetchCampaignListAnalytics']),
    fetchCampaignAnalytics() {

    }
  }
})
</script>
