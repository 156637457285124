<template>
  <div v-if="brand.bio_links">
    <div class="child_item "
         v-for="link in brand.bio_links">
      <div class="clear">
        <div class="col col_20">
          <div class="padding_left align_center">
            <template v-if="brand.bio_links.length > 0">
                          <span
                            @click.prevent=""
                            data-toggle="collapse" :data-parent="'#'+brand._id"
                            :data-target="'#'+link._id" v-tooltip="'View content sections'"
                            class="collapse_icon">
                            <i class="fal fa-angle-down"></i>
                          </span>
            </template>

            <div class="content">

              <div class="name">
                {{ link.page_title }}
              </div>
              <div class="clearfix"></div>

              <div class="url"
                   v-if="link.state === 'active'">

                <template>
                  https://{{ link.shorten_domain }}/{{ link.shorten_url }}
                  <a :href="'https://' + link.shorten_domain + '/' + link.shorten_url"
                     target="_blank"
                     class="external_link">
                    <i class="far fa-link"></i>
                  </a>
                </template>
              </div>
            </div>

          </div>
        </div>
        <div class="text-center col col_10">
          <p>-</p>
        </div>

        <div class="text-center col col_10">
          <span v-if="link.bioFetchStats">
            {{ commaSeparatedNumber(getLinkTotalVisitors(link)) }}
          </span>
          <skeleton v-else></skeleton>
        </div>
        <div class="text-center col col_10">
          {{ commaSeparatedNumber(getLinkUniqueVisitors(link)) }}
        </div>
        <div class="text-center col col_10">
          <p>-</p>
          <!--          {{ commaSeparatedNumber(getLinkTotalClicksAndConversions(link)) }}-->
        </div>
        <div class="text-center col col_10">
          <p>-</p>
          <!--          {{ getLinkConversionRate(link) }}%-->
        </div>
        <div class="text-center col col_10">
          <p>{{
              link.created_at |
                relativeCaptilize
            }}</p>
        </div>
      </div>

      <div :id="link._id" class="collapse child_row last_child">

        <div class="child_item "
             v-for="content in link.bio_content">
          <div class="clear">

            <div class="col col_20">

              <div class="padding_left align_top profile_box cta_link_box">
                <div class="content">
                  <div class="name">
                    <span v-if="content.show_title">{{ content.title }} | </span>
                    <span style="text-transform: capitalize">{{ content.type }}</span>
                  </div>

                  <div class="clearfix"></div>

                  <div class="url" v-for="social_link in content.channels"
                       v-if="content.type === 'social' && social_link">
                    <template>
                                            <span class="d-flex">
                                                <span class="d-inline-block truncate_url">{{ social_link }}</span>
                                                <a :href="social_link"
                                                   target="_blank"
                                                   class="external_bio_link">
                                                    <i class="far fa-link"></i>
                                                </a>
                                            </span>
                    </template>
                  </div>

                  <div class="url" v-for="social_content in content.links"
                       v-if="content.type === 'links' && social_content">
                    <template>
                                            <span class="d-flex" v-tooltip="social_content.title">
                                                <span
                                                  class="d-inline-block truncate_url">{{ social_content.url }}</span>
                                                <a :href="social_content.url"
                                                   target="_blank"
                                                   class="external_bio_link">
                                                    <i class="far fa-link"></i>
                                                </a>
                                            </span>
                    </template>
                  </div>

                  <div class="url" v-for="social_content in content.posts"
                       v-if="content.type === 'rss' && social_content">
                    <template>
                                            <span class="d-flex" v-tooltip="social_content.title">
                                                <span
                                                  class="d-inline-block truncate_url">{{ social_content.link }}</span>
                                                <a :href="social_content.link"
                                                   target="_blank"
                                                   class="external_bio_link">
                                                    <i class="far fa-link"></i>
                                                </a>
                                            </span>
                    </template>
                  </div>
                </div>
              </div>

            </div>

            <div class="text-center col col_10">
              <p>-</p>
            </div>

            <div class="text-center col col_10">
              {{ commaSeparatedNumber(getLinkTotalVisitors(content)) }}
            </div>
            <div class="text-center col col_10">
              {{ commaSeparatedNumber(getLinkUniqueVisitors(content)) }}
            </div>
            <div class="text-center col col_10">
              {{ commaSeparatedNumber(getLinkTotalClicksAndConversions(content)) }}
            </div>
            <div class="text-center col col_10">
              {{ getLinkConversionRate(content) }}%
            </div>
            <div class="text-center col col_10">
              <p>{{
                  content.created_at |
                    relativeCaptilize
                }}</p>
            </div>
          </div>

        </div>

      </div>
    </div>

    <!--        <div-->
    <!--                class="child_item inner_pagination"-->
    <!--                v-if="cta.total_links > 10"-->
    <!--        >-->
    <!--            <div class="clear pagination_container">-->
    <!--                <button-->
    <!--                        v-if="cta.links.current_page > 1"-->
    <!--                        @click.prevent="fetchCampaignByLinks(cta.links.current_page-1,cta._id, cta_index, brand_index, cta)"-->
    <!--                        class="btn_prev btn&#45;&#45;cta btn&#45;&#45;small"-->
    <!--                >-->
    <!--                    <i-->
    <!--                            class="icon_prev fa fa-caret-left"-->
    <!--                            aria-hidden="true"-->
    <!--                    ></i><span>previous</span>-->
    <!--                </button>-->
    <!--                <button-->
    <!--                        v-else-->
    <!--                        class="btn_prev btn&#45;&#45;cta btn&#45;&#45;small"-->
    <!--                        disabled="disabled"-->
    <!--                >-->
    <!--                    <i-->
    <!--                            class="icon_prev fa fa-caret-left"-->
    <!--                            aria-hidden="true"-->
    <!--                    ></i><span>previous</span>-->
    <!--                </button>-->

    <!--                <span class="numbering">{{cta.links.current_page}} of {{cta.links.last_page}}</span>-->

    <!--                <button-->
    <!--                        @click.prevent="fetchCampaignByLinks(cta.links.current_page+1,cta._id, cta_index, brand_index, cta)"-->
    <!--                        v-if="cta.links.current_page < cta.links.last_page"-->
    <!--                        class="btn_next btn&#45;&#45;cta btn&#45;&#45;small"-->
    <!--                >-->
    <!--                    <span>next</span><i-->
    <!--                        class="icon_next fa fa-caret-right"-->
    <!--                        aria-hidden="true"-->
    <!--                ></i>-->

    <!--                </button>-->
    <!--                <button-->
    <!--                        v-else-->
    <!--                        disabled="disabled"-->
    <!--                        class="btn_next btn&#45;&#45;cta btn&#45;&#45;small"-->
    <!--                >-->
    <!--                    <span>next</span><i-->
    <!--                        class="icon_next fa fa-caret-right"-->
    <!--                        aria-hidden="true"-->
    <!--                ></i>-->

    <!--                </button>-->

    <!--            </div>-->

    <!--        </div>-->
  </div>
</template>
<script>

import BioAnalyticsLinkDetailsSection from './BioAnalyticsLinkDetailsSection'

export default ({
  name: 'bio-analytics-links-section',
  componenets: {
    BioAnalyticsLinkDetailsSection
  },
  props: {
    brand: {
      default: {},
      type: Object
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    getLinkTotalLinks(link) {
      return (link.total_links) ? link.total_links : 0
    },
    getLinkTotalVisitors(link) {
      return (link.total_visitors) ? link.total_visitors : 0
    },
    getLinkUniqueVisitors(link) {
      return (link.unique_visitors) ? link.unique_visitors : 0
    },
    getLinkTotalClicksAndConversions(link) {
      return (link.total_clicks_and_conversions) ? link.total_clicks_and_conversions : 0
    },
    getLinkConversionRate(link) {
      return (link.conversion_rate) ? link.conversion_rate : 0
    }
  }
})
</script>

<style>
.truncate_url {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
