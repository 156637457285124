<template>
  <div class="clear">

    <div class="col col_20">

      <div class="padding_left align_top profile_box cta_link_box">
        <div class="avatar">
          <div v-if="link.information && link.information.favicon_url"
               class="brand_img"
               :style="brandImageLink(link.information.favicon_url)"></div>

          <div v-else class="brand_img"
               style='background: url("/img/globe.svg") center center / cover;'></div>

        </div>
        <div class="content">

          <div class="name"
               v-if="link.information && link.information.meta && link.information.meta.title">
            {{
              link.information.meta.title
            }}
          </div>
          <div class="clearfix"></div>

          <div class="url"
               v-if="link.status">

            <template>
              {{ link.domain.url }}{{ link.shorten_url }}
              <a target="_blank"
                 :href="link.domain.url+link.shorten_url" v-tooltip="'Visit link'"
                 class="external_link">
                <i class="far fa-link"></i>
              </a>
            </template>
          </div>
          <div class="url" v-else>
            <template>
              {{ link.domain.url }}{{ link.shorten_url }}
            </template>
            <a href="javascript:;"
               v-tooltip="'Visit link'"
               class="external_link">
              <i class="far fa-link"></i>
            </a>
          </div>
        </div>
      </div>

    </div>
    <div class="text-center col col_10">
      <p>-</p>
    </div>

    <div class="text-center col col_10">
      <!--<p>{{link.analytics.length}}</p>-->
      <p v-if="link.fetchStats">{{ commaSeparatedNumber(getLinkTotalVisitors) }}</p>
      <skeleton v-else></skeleton>
      <!--{{getLinkTotalVisits(link._id,campaign.stats)}}-->
    </div>
    <div class="text-center col col_10">
      <p v-if="link.fetchStats">{{ commaSeparatedNumber(getLinkUniqueVisitors) }}</p>
      <skeleton v-else></skeleton>
      <!--            {{getLinkTotalVisits(link._id,campaign.stats,'unique')}}-->
    </div>
    <div class="text-center col col_10">
      <p v-if="link.fetchStats">{{ commaSeparatedNumber(getLinkTotalClicksAndConversions) }}</p>
      <skeleton v-else></skeleton>
      <!--{{
      getLinkTotalEngagement(link._id,campaign.stats)
      }}-->
      <!--<p>{{getLinkTotalEngagement(link.analytics,'click')}}</p>-->
    </div>
    <div class="text-center col col_10">
      <p v-if="link.fetchStats">{{ getLinkConversionRate }}%</p>
      <skeleton v-else></skeleton>
      <!--{{getRate(getLinkTotalEngagement(link._id,campaign.stats),getLinkTotalVisits(link._id,campaign.stats))}}-->

      <!--<p>{{getRate(getLinkTotalEngagement(link.analytics,'click'),link.analytics.length)}}%</p>-->
    </div>
    <div class="text-center col col_10">
      <p>{{
          link.created_at |
            relativeCaptilize
        }}</p>
    </div>
    <div class="text-right col col_10">
      <div class="btn_action">
        <div class="icon_round"
             v-tooltip.top-center="'View Stats'">
          <a class="text"
             @click.prevent="linkDetailView(brand, campaign, link)">
            <i class="far fa-line-chart"></i>
          </a>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { shortenDomainURL } from '@/config/api'

export default ({
  name: 'analytics-link-section',
  props: {
    link: {},
    brand: {},
    campaign: {}
  },
  data () {
    return {
      shorten_domain_url: shortenDomainURL
    }
  },
  computed: {
    getLinkTotalLinks () {
      return (this.link.total_links) ? this.link.total_links : 0
    },
    getLinkTotalVisitors () {
      return (this.link.total_visitors) ? this.link.total_visitors : 0
      // return (this.link.total_hits) ? this.link.total_hits : 0
    },
    getLinkUniqueVisitors () {
      return (this.link.unique_visitors) ? this.link.unique_visitors : 0
    },
    getLinkTotalClicksAndConversions () {
      return (this.link.total_clicks_and_conversions) ? this.link.total_clicks_and_conversions : 0
    },
    getLinkConversionRate () {
      return (this.link.conversion_rate) ? this.link.conversion_rate : 0
    }
  },
  methods: {}
})
</script>
